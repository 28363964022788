export interface ICompDefault {
  /**
   * Any classes to be added to the component
   */
  className?: string;
  /**
   * Id of an html element
   */
  id?: string;
  tabIndex?: number;
}

export enum MONTH {
  'January' = 0,
  'February' = 1,
  'March' = 2,
  'April' = 3,
  'May' = 4,
  'June' = 5,
  'July' = 6,
  'August' = 7,
  'September' = 8,
  'October' = 9,
  'November' = 10,
  'December' = 11,
}

/**
 * **Bootstrap 4.6** media breakpoints.
 *
 * Note from Bootstrap on these numbers:
 *
 * Note that since browsers do not currently support range context queries,
 * we work around the limitations of min- and max- prefixes and viewports with
 * fractional widths (which can occur under certain conditions on high-dpi devices, for instance)
 *  by using values with higher precision for these comparisons.
 */
export const BREAKPOINTS = {
  xsmall: 575.98,
  small: 767.98,
  medium: 991.98,
  large: 1199.98,
};

/**
 * The time in ms we are to wait before executing the timeout content.
 */
export const KEYPRESS_WAIT = 400;

/**
 * Timeout used for server calls
 */
export const TIMEOUT = 120000;

export enum EmploymentType {
  FullTime = 'fulltime',
  PartTime = 'parttime',
  FullTimeRemote = 'fulltimeremote',
  PartTimeRemote = 'parttimeremote',
}

export enum EmploymentTypeVisual {
  FullTime = 'Full time',
  PartTime = 'Part time',
  FullTimeRemote = 'Full time | remote',
  PartTimeRemote = 'Part time | remote',
}

export type SkillType = 'skill' | 'subskill' | 'role';

export type SkillChildren = Record<string, SkillChild>;

export interface SkillChild {
  name: string; // de-normalized name copied from Skill
  type: SkillType; // de-normalized type copied from Skill
  children?: SkillChildren; // Recursive child hierarchy, or undefined if this is a leaf skill
}

export interface ISkill {
  id: string;
  name: string;
  type: SkillType;
  parentId?: string; // Only for subskills, the parent Skill ID
  children?: SkillChildren; // Only for roles, the child skill hierarchy
  description?: string;
  time?: number;
  level?: number;
  progress?: number;
  icon?: React.ElementType;
  visible?: boolean; // Used in profile to show/hide skills
  assessment?: {
    score: number;
    reliability: number;
  };
}

type Uuid = string;

export interface SkillsWithLessons {
  topics: Readonly<Record<Uuid, Readonly<Topic>>>;
  chapters: Readonly<Record<Uuid, Readonly<Chapter>>>;
  lessons: Readonly<Record<Uuid, Readonly<Lesson>>>;
}

interface Common {
  name: string;
}
interface Topic extends Common {
  code: string;
  chapters: Uuid[];
  timeToComplete: number;
}

interface Chapter extends Common {
  topic: Uuid;
  lessons: Uuid[];
  timeToComplete: number;
}

interface Lesson extends Common {
  path: string;
  deleted: boolean;
  chapter: Uuid;
  topic: Uuid;
}

export interface ErrorType {
  code: string;
  description?: string;
  context?: string;
  list?: any;
  meta?: any;
}

export type ParentSkill = ISkill & { type: 'skill' };

export type SkillWithProgress = ISkill & { progress: number };
export type ParentSkillWithLevel = ParentSkill & { progress: number };
export const filterSkillsWithProgress = (
  skills: readonly ISkill[],
): SkillWithProgress[] =>
  skills.filter((skill): skill is SkillWithProgress => skill.progress !== undefined);

export const softSkillsSkillId = '0c26289b-3f19-4100-8daf-c8fbd164dffc';
export const generalProblemSolvingSkillId =
  'f4cfc4c9-ed2e-4fd5-ad21-1b681bcd10c7';
export const laravelSkillId = 'd1f67e4b-8746-41c1-baf6-61c79d9d1a34';
export type SoftSkill = ParentSkill & { id: typeof softSkillsSkillId };

export function isParentSkill(skill: ISkill): skill is ParentSkill {
  return skill.type === 'skill';
}
export const isSoftSkillId = (id: string): id is typeof softSkillsSkillId =>
  id === softSkillsSkillId;
export const isSoftSkill = (skill: ISkill): skill is SoftSkill =>
  isParentSkill(skill) && isSoftSkillId(skill.id);

export enum WebSocketStates {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
