export type IUserLocation = Record<string, ICountry>;

export interface IUserPersonal {
  firstName: string;
  lastName: string;
  profilePicture?: string;
  aboutMe?: string;
  country?: IUserLocation;
  currentJobTitle?: string;
  dateOfBirth?: Date | null;
}

export interface IContactField {
  value: string;
  visible: boolean;
}

export interface IContact {
  email?: IContactField;
  phone?: IContactField;
  website?: IContactField;
  linkedin?: IContactField;
  facebook?: IContactField;
  twitter?: IContactField;
  github?: IContactField;
  instagram?: IContactField;
}

export interface IContacts {
  website?: IContact;
  twitter?: IContact;
  github?: IContact;
  phone?: IContact;
  facebook?: IContact;
  linkedin?: IContact;
  instagram?: IContact;
  email?: IContact;
}

export interface IEducation {
  degree?: string;
  period?: string;
  institution?: string;
  place?: string;
  description?: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IEducationResponse {
  degree?: string;
  period?: string;
  institution?: string;
  place?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
}

export interface IExperience {
  company?: string;
  period?: string;
  position?: string;
  location?: string;
  duties?: string[];
  dutiesString?: string; // always defined by means of mapExperience
  startDate: Date | null;
  endDate: Date | null;
}

export interface IExperienceResponse {
  company?: string;
  period?: string;
  position?: string;
  location?: string;
  duties?: string[];
  startDate?: string;
  endDate?: string;
}

export interface ISkill {
  id: string;
  name: string;
  type: 'skill' | 'subskill' | 'role';
  level?: number;
  progress?: number;
  visible?: boolean;
}

export interface IActivityScore {
  stars: number;
  lessonsRead: number;
  exercisePoints: number;
  quizPoints: number;
  totalPoints: number;
}

export interface ICertification {
  id: string;
  title: string;
  verifyUrl: string;
  dateCompleted: number;
  visible: boolean;
}

export interface ISpaces {
  spaceId: string;
  spaceUrl: string;
  description: string;
  pictureUrl: string;
  visible: boolean;
}

export interface ITagType {
  tagId: string;
  tagName: string;
}

export interface IUserProfile {
  userId: string;
  personal?: IUserPersonal;
  contacts?: IContact;
  experience?: IExperience[];
  education?: IEducation[];
  companies?: ICompany[];
  interests?: ITagType[];
  certifications?: ICertification[];
  activity?: IActivityScore;
  aboutMe?: string;
  openToWork?: boolean;
  isSuspicious?: boolean;
}

export enum visibilityOptions {
  Private = 'private',
  Public = 'public',
}

export interface IUserProfileSettings {
  userId?: string;
  personal?: visibilityOptions;
  aboutMe?: visibilityOptions;
  contacts?: visibilityOptions;
  company?: visibilityOptions;
  education?: visibilityOptions;
  experience?: visibilityOptions;
  skills?: visibilityOptions;
  activity?: visibilityOptions;
  certifications?: visibilityOptions;
  spaces?: visibilityOptions;
  interests?: visibilityOptions;
}

export interface FilterType {
  id: string;
  title: string;
  value: string;
  count?: number;
  checked?: boolean;
  extra?: any;
}

export type ILanguages = Record<string, string>;

export interface ErrorType {
  hasError: boolean;
  message?: string;
  callback?: () => void;
}

export interface IChakraReactSelect {
  label: string;
  value: string;
}

export type CompanyField =
  | 'name'
  | 'position'
  | 'phone'
  | 'email'
  | 'contactName'
  | 'country'
  | 'logo';

export interface ICountry {
  /**
   * Country name
   *
   * @type {string}
   */
  name: string;
  /**
   * Country regions
   *
   * @type {ICountry}
   */
  regions?: Record<string, ICountry>;
}

export type IJobLocation = Record<string, ICountry>;

export interface ICompany {
  id?: string;
  name?: string;
  position?: string;
  phone?: string;
  email?: string;
  logo?: string;
  contactName?: string;
  country?: IJobLocation;
}

export interface CroppedArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IUserPersonalResponse {
  firstName: string;
  lastName: string;
  profilePicture?: string;
  aboutMe?: string;
  country?: IUserLocation;
  currentJobTitle?: string;
  dateOfBirth?: string;
}

export enum visibilityOptionsVisual {
  Private = 'Private',
  Recruiter = 'Recruiter',
  Public = 'Public',
}
